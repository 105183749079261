import React, { useState, useRef, useEffect, useCallback } from 'react';
import isEqual from 'lodash/isEqual';
import {filter, isUndefined} from 'lodash';

//工具类
const getServiceLangCode = (i18n) => {
  return (i18n?.language==='zh-CN'?'zh':i18n?.language)??'zh';
};
const getProductImg = (prod) => {
  return prod.mime === null ? null : filter(prod.mime, { mimePurpose: 'thumbnail' })?.[0]?.['mimeSource']?.[0]?.['#text'];
};
const getValueByLanguage = (i18n, value) => {
  if (!i18n) {
    return (typeof value === 'string' ? value : value['zh']);
  }
  const local = getServiceLangCode(i18n);
  if (isUndefined(value))
    return value;
  return (typeof value === 'string' ? value : value[local]);
};
const openSFUrl = (url) => {
  //window.parent?.location.href = url;
  window?.open(url, '_blank');
};
const Utills = {getServiceLangCode,
  getValueByLanguage,
  getProductImg,
  openSFUrl
};
export default Utills;
