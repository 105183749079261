import React, {
  lazy, Suspense, useCallback, useEffect, useState
} from 'react';
import { Provider, useDispatch } from 'react-redux';
import {
  BrowserRouter, Route, Redirect, useHistory,
} from 'react-router-dom';
import CacheRoute, { CacheSwitch } from 'react-router-cache-route';
import FetchBackend from 'i18next-fetch-backend';
import BackendAdapter from 'i18next-multiload-backend-adapter';
import LanguageDetector from 'i18next-browser-languagedetector';
import { useTranslation, initReactI18next } from 'react-i18next';

import i18n from '../services/i18n';
import { getLanuageJsonAction, getTenantLanuageAction, getUserShareAction } from '@ivymobi/orbit-core/business/share';
import _, { isUndefined } from 'lodash';
import Loader from './loaders';
import { getTenantInfoAction } from '@ivymobi/orbit-core/business/productCenter';
import { queryParse } from '@ivymobi/orbit-core';
const PreProcess = ({children}) => {
  const [lanunages, setLanuage] = useState([]);
  const dispatch  = useDispatch();
  useEffect(() => {
    const fetchData = async () => {
      const domain = document.domain.split('.');  //domain[0]
      const params = queryParse(location.search);
      // demoidc  demo-dehn   mfcsevenstar-lite  demoegger
      // abbdrives  sanlingdemo  demotederic demoabb  tgcq2023  xltc-lite  cnlxv
      // abbelectrificationstaging abbelectrification loyy  evang -> fsi
      const innerLanguage = params['language']??undefined;
      const res = await dispatch(getTenantInfoAction({ url: (domain[0] === '127'||domain[0] === '192') ? 'tgcq2023' : domain[0] }));
      const tnt_info = _.get(res, ['data', 0], {}) || {};

      const result = await  dispatch(getTenantLanuageAction({tenant:{id:tnt_info.tnt_id}}));
      const _languages = _.keyBy(result.data.filter(i => { return i.status;}), 'languageid');
      if (!isUndefined(innerLanguage)) {
        i18n
        // learn more: https://github.com/i18next/i18next-multiload-backend-adapter
        .use(BackendAdapter)
        // detect user language
        // learn more: https://github.com/i18next/i18next-browser-languageDetector
        .use(LanguageDetector)
        // pass the i18n instance to react-i18next.
        .use(initReactI18next)
        // init i18next
        // for all options read: https://www.i18next.com/overview/configuration-options
        .use(FetchBackend).init({
          supportedLngs:[innerLanguage],
          lng: innerLanguage,
          debug: false,
          // fallbackLng:'zh-CN',
          ilanguages:[_languages[innerLanguage??'']],
          interpolation: {
            escapeValue: false // not needed for react as it escapes by default
          },
          backend: {
            loadPath: function(lngs, namespaces) {
              console.log(lngs, namespaces, _languages[lngs]?.fileurl);

              return _languages[lngs]?.fileurl;
            }
          },
        }, (err, t) => {
          // 初始化完成后执行的回调函数，此时可以获取所有语言代码（如果有需要）
          // const allLanguages = i18n.languages; // 注意这里可能需要稍后访问，因为初始化是异步的。确保在回调函数内部或初始化完成后访问。
          // console.log(err, t, allLanguages); // 输出所有语言代码，例如: ['en', 'es']（取决于你的资源）
          setLanuage(result.data);
        });

        return;
      }
      i18n
      // learn more: https://github.com/i18next/i18next-multiload-backend-adapter
      .use(BackendAdapter)
      // detect user language
      // learn more: https://github.com/i18next/i18next-browser-languageDetector
      .use(LanguageDetector)
      // pass the i18n instance to react-i18next.
      .use(initReactI18next)
      // init i18next
      // for all options read: https://www.i18next.com/overview/configuration-options
      .use(FetchBackend).init({
        supportedLngs:Object.keys(_languages),
        // lng: 'zh-CN',
        debug: false,
        //fallbackLng:'zh-CN',
        ilanguages:result.data,
        interpolation: {
          escapeValue: false // not needed for react as it escapes by default
        },
        backend: {
          loadPath: function(lngs, namespaces) {
            // console.log(lngs, namespaces, _languages[lngs]?.fileurl);

            return _languages[lngs]?.fileurl;
          }
        },
      }, (err, t) => {
        // 初始化完成后执行的回调函数，此时可以获取所有语言代码（如果有需要）
        // const allLanguages = i18n.languages; // 注意这里可能需要稍后访问，因为初始化是异步的。确保在回调函数内部或初始化完成后访问。
        // console.log(err, t, allLanguages); // 输出所有语言代码，例如: ['en', 'es']（取决于你的资源）
        setLanuage(result.data);
      });

    };
    fetchData();
  }, []);

  if (lanunages.length == 0) {
    return <Loader/>;
  }
  console.log('lanunageslanunageslanunages', lanunages,i18n);
  return children;
};

export default PreProcess;