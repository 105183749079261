import axios from 'axios';

export const resolveUrl = (url: string, base?: string) => {
  const fullUrl = new URL(url, base || `${window.location.protocol}//${window.location.host}/api/`);
  return fullUrl.href;
};

export const resolveAIUrl = (url: string, base?: string) => {
  const fullUrl = new URL(url, base || `${window.location.protocol}//${window.location.host}/ai/`);
  return fullUrl.href;
};

export const getItem = (key: string): Promise<string> => {
  return new Promise((resolve, reject) => {
    const value = localStorage.getItem(key);
    if (value) {
      resolve(value);
    }
    reject(new Error('not exit'));
  });
};

export const setItem = (key: string, value: string): Promise<void> => {
  return new Promise((resolve) => {
    localStorage.setItem(key, value);
    resolve();
  });
};

export const removeItem = (key: string): Promise<void> => {
  return new Promise((resolve) => {
    localStorage.removeItem(key);
    resolve();
  });
};
// export async function downloadFile(data) {
//   const headers = Object.assign(
//     {'content-type': 'application/json'},
//     data.token ? {'Authorization': `Bearer ${data.token}`} : {}
//   );
//   return request(resolveUrl(data.url, data.base), {
//     method: 'GET',
//     headers,
//     responsrType: 'blob'
//   });
// };
export const downloadFile = (data) => {
  const headers = Object.assign(
    {'content-type': 'application/json'},
    data.token ? {'Authorization': `Bearer ${data.token}`} : {}
  );
  return fetch(resolveUrl(data.url, data.base), {
    method: 'GET',
    responseType: 'blob',
    headers,
  }).then((res) => {
    res.blob().then((blob) => {
      // saveBlob(blob, 'abc.csv');
      const filename = data.fileName + '.csv';
      if (window.navigator.msSaveOrOpenBlob) {
        navigator.msSaveBlob(blob, filename);
      } else {
        const anchor = document.createElement('a');
        const body = document.querySelector('body');
        anchor.href = window.URL.createObjectURL(blob);
        anchor.download = filename;
        anchor.style.display = 'none';
        body.appendChild(anchor);
        anchor.click();
        body.removeChild(anchor);
        window.URL.revokeObjectURL(anchor.href);
      }
    });
  });

};

export const downloadFileByPost = (data) => {
  const headers = Object.assign(
    {'content-type': 'application/json'},
    data.token ? {'Authorization': `Bearer ${data.token}`} : {}
  );
  return fetch(resolveUrl(data.url, data.base), {
    method: 'POST',
    responseType: 'blob',
    headers,
    body: JSON.stringify(data.body)
  }).then((res) => {
    res.blob().then((blob) => {
      // saveBlob(blob, 'abc.csv');
      
      const filename = data.fileName??'';
      if (window.navigator.msSaveOrOpenBlob) {
        navigator.msSaveBlob(blob, filename);
      } else {
        const anchor = document.createElement('a');
        const body = document.querySelector('body');
        anchor.href = window.URL.createObjectURL(blob);
        anchor.download = filename;
        anchor.style.display = 'none';
        body.appendChild(anchor);
        anchor.click();
        body.removeChild(anchor);
        window.URL.revokeObjectURL(anchor.href);
      }
    });
  });

};

interface FetchProps {
  base?: string;
  url: string;
  token?: string;
  acceptLanguage?: string;
}
export function getJSON<Result = unknown>(
  data: FetchProps & { query?: Record<string, unknown> }
): Promise<Result> {
  const headers = Object.assign(
    {'content-type': 'application/json'},
    data.token ? {'Authorization': `Bearer ${data.token}`} : {}
  );

  return fetch(resolveUrl(data.url, data.base), {
    method: 'get',
    headers
  }).then((res) => {
    if (res.ok) {
      return res.json();
    }
    if (res.status >= 400 && res.status < 500) {
      return res.json().then((message) => {
        throw message;
      });
    }
    throw new Error(res.statusText);
  });
}

export const putJSON = (data) => {
  const headers = Object.assign(
    {'content-type': 'application/json'},
    data.token ? {'Authorization': `Bearer ${data.token}`} : {}
  );

  return fetch(resolveUrl(data.url, data.base), {
    method: 'put',
    headers,
    body: JSON.stringify(data.body)
  }).then((res) => {
    if (res.ok) {
      if (res.status === 204) {
        return;
      }
      return res.json();
    }
    if (res.status >= 400 && res.status < 500) {
      return res.json().then((message) => {
        throw message;
      });
    }
    throw new Error(res.statusText);
  });
};

export function postJSON<Result = unknown, Body = unknown>(
  data: FetchProps & { body?: Body }
): Promise<Result> {
  const headers = Object.assign(
    {'content-type': 'application/json'},
    data.token ? {'Authorization': `Bearer ${data.token}`} : {}
  );
  return fetch(resolveUrl(data.url, data.base), {
    method: 'post',
    headers,
    body: JSON.stringify(data.body)
  }).then((res) => {
    if (res.ok) {
      if (res.status === 204) {
        return;
      }
      return res.json();
    }
    if (res.status >= 400 && res.status < 500) {
      return res.json().then((message) => {
        throw message;
      });
    }
    throw new Error(res.statusText);
  });
}

export function deleteJSON<Result = unknown, Body = unknown>(
  data: FetchProps & { body?: Body }
): Promise<Result> {
  const headers = Object.assign(
    {'content-type': 'application/json'},
    data.token ? {'Authorization': `Bearer ${data.token}`} : {}
  );

  return fetch(resolveUrl(data.url, data.base), {
    method: 'delete',
    headers,
    body: JSON.stringify(data.body)
  }).then((res) => {
    if (res.ok) {
      if (res.status === 204) {
        return;
      }
      return res.json();
    }
    if (res.status >= 400 && res.status < 500) {
      return res.json().then((message) => {
        throw message;
      });
    }
    throw new Error(res.statusText);
  });
}

export const upload = (data) => {
  const headers = data.token ?
    {'Authorization': `Bearer ${data.token}`}
    : {};

  const body = new FormData();
  body.append('tenantId', data.tenantId);
  body.append('file', data.file);

  return fetch(resolveUrl(data.url, data.base), {
    method: 'post',
    headers,
    body
  }).then((res) => {
    if (res.ok) {
      return res.json();
    }
    if (res.status >= 400 && res.status < 500) {
      return res.json().then((message) => {
        throw message;
      });
    }
    throw new Error(res.statusText);
  });
};
export const axiosUpload = (data, onProgress) => {
  const headers = data.token ?
    {'Authorization': `Bearer ${data.token}`}
    : {};

  const body = new FormData();
  body.append('tenantId', data.tenantId);
  body.append('file', data.file);


  let stime = new Date().getTime();
  let sloaded = 0;
  return axios({
    url:resolveUrl(data.url, data.base),
    method: 'post',
    headers,
    data:body,
    onUploadProgress: ({total, loaded}) => {
      const endTime = new Date().getTime();
      const dTime = (endTime - stime) / 1000; //毫秒换算成秒
      const dloaded = loaded - sloaded;
      let speed = dloaded / dTime;
      stime = new Date().getTime();
      sloaded = loaded;
      let unit = 'B/s'; //不足1kb，以b/s来计算速度
      // kb/s
      if (speed / 1024 > 1) { //当大小超过1024b(即1kb时)，以kb/s来计算速度
        unit = 'KB/s';
        speed = speed / 1024;
      }
      // mb/s
      if (speed / 1024 > 1) { //当大小超过1024Kb(即1mb时)，以mb/s来计算速度
        unit = 'MB/s';
        speed = speed / 1024;
      }
      if (onProgress)
        onProgress(data.file, loaded/total, speed, unit);
    }
  }).then((res) => {
    if (res.status==200) {
      return res.data;

    }
    if (res.status >= 400 && res.status < 500) {
      return res.data;
    }
  });
};
export const getBlob = (data) => {
  const headers = Object.assign(
    // {'content-type': 'application/json'},
    data.token ? {'Authorization': `Bearer ${data.token}`} : {}
  );

  return fetch(resolveUrl(data.url, data.base), {
    method: 'get',
    headers
  }).then((res) => {
    if (res.ok) {
      return res.blob();
    }
    if (res.status >= 400 && res.status < 500) {
      return res.json().then((message) => {
        throw message;
      });
    }
    throw new Error(res.statusText);
  });
};
